import _ from "lodash";
import {stringify} from "query-string";
import Base64 from "base64-js";

interface IDecodedDataURI {
    mimeType: string;
    extension?: string;
    content?: string;
    attributes?: { [key: string]: string };
}

export default class Helpers {
    static validateEmail(email: string) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static arrayCompareValues(key, direction: 'ASC' | 'DESC') {
        return function (a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }
            const rawA = a[key];
            const rawB = b[key];
            const varA = (typeof rawA === 'string') ? rawA.toUpperCase() : rawA;
            const varB = (typeof rawB === 'string') ? rawB.toUpperCase() : rawB;

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (direction == 'DESC') ? (comparison * -1) : comparison
            );
        };
    }

    static getMessageException(ex) {
        if (ex.isAxiosError) {
            if (ex.response && ex.response.data != null) {
                return Helpers.dataToString(ex.response.data);
            }
            return ex.message;
        } else if (ex.message) {
            return ex.message;
        } else if (ex.errorResponseData) {
            return Helpers.dataToString(ex.errorResponseData)
        } else if (ex.error) {
            return Helpers.dataToString(ex.error);
        }
        return '';
    }

    static dataToString(data) {
        let strData = '';
        if (Array.isArray(data)) {
            for (const item of data) {
                const result = Helpers.dataToString(item);
                if (result && result !== '') {
                    if (strData !== '') {
                        strData += "\n";
                    }
                    strData += result;
                }
            }
        } else if (typeof (data) === 'object' && data !== undefined && data !== null) {
            Object.keys(data).map(key => {
                const result = Helpers.dataToString(data[key]);
                if (result && result !== '') {
                    if (strData !== '') {
                        strData += "\n";
                    }
                    strData += result;
                }
            });
        } else {
            return String(data);
        }
        return strData;
    }

    static getForeignKeyFieldsFromSchema(schema) {
        return Helpers.getFieldsFromSchema(schema)
            .filter(f => Array.isArray(f.schema.foreignKeys) && f.schema.foreignKeys.length);
    }

    static getTagsFromSchema(data: any, basePath: string = '') {
        const fullText = data.hasOwnProperty('isFullText') ? data['isFullText'] : false;
        let tags = data.hasOwnProperty('tags') ? data['tags'].split(',').filter(t => t.trim() !== '').map(tag => ({
            name: tag.trim(),
            path: basePath,
            is_fulltext: fullText
        })) : [];
        if (fullText && tags.length === 0) {
            tags.push({name: '', path: basePath, is_fulltext: fullText});
        }
        if (data.type === 'object') {
            Object.keys(data.properties).map(k => Helpers.getTagsFromSchema(data.properties[k], `${basePath}/${k}`)).forEach((subTags: any) => {
                tags = [...tags, ...subTags]
            });
        } else if (data.type === 'array') {
            Helpers.getTagsFromSchema(data.items, `${basePath}/*`).forEach(subTag => tags.push(subTag));
        }

        return tags;
    }

    static getFlagsFromSchema(data: any, basePath: string = '') {
        let flags = data.hasOwnProperty('flags') && data['flags'].trim() !== '' ? data['flags'].split(',').map(flag => ({
            name: flag.trim(),
            path: basePath
        })) : [];
        if (data.type === 'object' || data.type === 'catalog') {
            Object.keys(data.properties).map(k => Helpers.getFlagsFromSchema(data.properties[k], `${basePath}/${k}`)).forEach((subFlags: any) => {
                flags = [...flags, ...subFlags]
            });
        } else if (data.type === 'array') {
            flags = [...flags, ...Helpers.getFlagsFromSchema(data.items, `${basePath}/*`)];
        }

        return flags;
    }

    static removeRequiredFieldsSchema(data) {
        if (data.required) {
            delete data.required;
        }
        if (data.type === 'object') {
            Object.keys(data.properties).forEach(k => Helpers.removeRequiredFieldsSchema(data.properties[k]));
        } else if (data.type === 'array') {
            Helpers.removeRequiredFieldsSchema(data.items);
        }
        return data;
    }

    static removeReadOnlyFieldsSchema(data) {
        if (data.hasOwnProperty('readOnly')) {
            delete data.readOnly;
        }
        if (data.type === 'object') {
            Object.keys(data.properties).forEach(k => Helpers.removeReadOnlyFieldsSchema(data.properties[k]));
        } else if (data.type === 'array') {
            Helpers.removeReadOnlyFieldsSchema(data.items);
        }
        return data;
    }

    static formatOpenApiSchema(data) {
        let schemaId = data['x-id'];
        if (data['$id'] != null) {
            schemaId = data['$id'];
        }
        for (const key of ['tags', 'triggers', 'flags', 'primaryKey', 'foreignKeys', '$id', 'isFullText', '$incrementIndex', '$path']) {
            if (data.hasOwnProperty(key)) {
                delete data[key];
            }
        }
        if (data.type === 'catalog') {
            data['$ref'] = '#/components/schemas/FieldCatalogType';
            delete data.properties;
            delete data.type;
            delete data.required;
        } else if (data.type === 'datetime') {
            data.type = 'string'
            data.format = 'date-time';
        } else if (data.type === 'date') {
            data.type = 'string'
            data.format = 'date';
        }
        if (data.type === 'object') {
            Object.keys(data.properties).forEach(k => Helpers.formatOpenApiSchema(data.properties[k]));
        } else if (data.type === 'array') {
            Helpers.formatOpenApiSchema(data.items);
        } else if (data.type === 'file') {
            data['$ref'] = '#/components/schemas/FieldFileType';
            delete data.type;
            delete data.consumes;
            delete data.antivirus;
        }
        if (schemaId != null) {
            if (data.type) {
                data['x-id'] = schemaId;
            } else if (data['$ref']) {
                const tmpSchema = Helpers.deepCopy(data);
                delete data['$ref'];
                data.allOf = [
                    tmpSchema,
                    {
                        'x-id': schemaId
                    }
                ]
            }
        }
        return data;
    }

    static cleanUpSchemaData(data) {
        for (const key of ['tags', 'flags', 'isFullText', '$indexes']) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                delete data[key];
            }
        }
        if (data.type === 'object') {
            Object.keys(data.properties).map(k => Helpers.cleanUpSchemaData(data.properties[k]));
        } else if (data.type === 'array') {
            Helpers.cleanUpSchemaData(data.items);
        }
        return data;
    }

    static deletePrimaryKeySchemaData(data) {
        if (data.type === 'object') {
            for (const key of Object.keys(data.properties)) {
                if (data.properties[key].primaryKey) {
                    delete data.properties[key];
                    if (data.required && data.required.indexOf(key) !== -1) {
                        data.required.splice(data.required.indexOf(key), 1);
                    }
                } else {
                    Helpers.deletePrimaryKeySchemaData(data.properties[key]);
                }
            }
        } else if (data.type === 'array') {
            Helpers.deletePrimaryKeySchemaData(data.items);
        }
        return data;
    }

    static deepCopy(data) {
        return JSON.parse(JSON.stringify(data));
    }

    static setTagsSchemaData(data: any, tags: any[], basePath: string = '') {
        let dataTags = [];
        tags.filter(tag => tag.path === basePath).forEach(tag => {
            data.isFullText = tag.is_fulltext;
            if (tag.name.length > 0) {
                dataTags.push(tag.name);
            }
        });
        data.tags = dataTags.join(",");
        if (data.type === 'object' || data.type === 'catalog') {
            Object.keys(data.properties).map(k => Helpers.setTagsSchemaData(data.properties[k], tags, `${basePath}/${k}`));
        } else if (data.type === 'array') {
            Helpers.setTagsSchemaData(data.items, tags, `${basePath}/*`);
        }
        return data;
    }

    static setFlagsSchemaData(data: any, flags: any[], basePath: string = '') {
        let dataFlags = [];
        flags.filter(tag => tag.path === basePath).forEach(flag => {
            if (flag.name.length > 0) {
                dataFlags.push(flag.name);
            }
        });
        const foreignKeyFlags = dataFlags.filter(f => f.startsWith('foreign-key'));
        const foreignKeyFlag = foreignKeyFlags.length > 0 ? foreignKeyFlags[0] : null;
        if (foreignKeyFlag) {
            const db = foreignKeyFlag.substring('foreign-key'.length);
            if (db.length !== 0) {
                const values = [];
                const part = db.substring(1).split('-');
                const databaseKey = part.shift();
                const foreignFieldsBetween = data.foreignFieldsBetween || [];
                part.forEach((fieldKey, fieldKeyIndex) => {
                    values.push({
                        fieldKey
                    });
                    if (foreignFieldsBetween.length > fieldKeyIndex && foreignFieldsBetween[fieldKeyIndex].length > 0) {
                        values.push(foreignFieldsBetween[fieldKeyIndex]);
                    }
                });
                data.foreignKeys = [{databaseKey, values}];
                if (data.foreignFieldsBetween !== undefined) {
                    delete data.foreignFieldsBetween;
                }
            }
            dataFlags = dataFlags.filter(f => !f.startsWith('foreign-key'));
        }
        data.flags = dataFlags.join(",");
        if (data.type === 'object') {
            Object.keys(data.properties).map(k => Helpers.setFlagsSchemaData(data.properties[k], flags, `${basePath}/${k}`));
        } else if (data.type === 'array') {
            Helpers.setFlagsSchemaData(data.items, flags, `${basePath}/*`);
        }
        return data;
    }

    static isInt(value: number): boolean {
        if (Number.isNaN(value)) {
            return false;
        }
        return value % 1 === 0;
    }

    static generateJsonSchemaToData(schema: any, required = false, emptyValue = false) {
        if (schema.type === 'array') {
            return emptyValue ? [] : [Helpers.generateJsonSchemaToData(schema.items)];
        } else if (schema.type === 'object' || schema.type === 'catalog') {
            let data = {};
            Object.keys(schema.properties).map(key => data[key] = Helpers.generateJsonSchemaToData(schema.properties[key], schema.required && schema.required.indexOf(key) !== -1), emptyValue);
            return data;
        }
        let value: any = '';
        if (schema.type === 'number' || schema.type === 'integer') {
            value = required && !schema.primaryKey ? 0 : undefined;
        } else if (schema.type === 'boolean') {
            value = false;
        } else if (schema.type === 'file') {
            return {
                '$documentId': 0
            }
        }
        if (Array.isArray(schema.enum) && schema.enum.length > 0) {
            value = schema.enum[0];
        }
        if (schema.default != null) {
            value = Helpers.formatDataForSchema(schema.default, schema);
        }

        return value;
    }

    static calcVH(v: number) {
        const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (v * h) / 100;
    }

    static calcVW(v: number) {
        const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        return (v * w) / 100;
    }

    static filterTypesSchema(schema, types: Array<string>, includedPrimaryKey = false) {
        if (types.indexOf(schema.type) === -1 || schema.primaryKey && !includedPrimaryKey) {
            return null;
        }
        if (schema.type === 'object' || schema.type === 'catalog') {
            let filteredSchemaProperties = {};
            Object.keys(schema.properties).forEach(key => {
                const filteredSchema = Helpers.filterTypesSchema(schema.properties[key], types, includedPrimaryKey);
                if (filteredSchema) {
                    filteredSchemaProperties[key] = filteredSchema;
                }
            });
            return {...schema, properties: filteredSchemaProperties};
        } else if (schema.type === 'array') {
            const filteredSchemaItems = Helpers.filterTypesSchema(schema.items, types, includedPrimaryKey);
            if (filteredSchemaItems) {
                return {...schema, items: filteredSchemaItems};
            }
            return null;
        }
        return schema;
    }

    static formatDataForSchema(data: any, schema: any, emptyValue = false) {
        if (schema.type === 'array') {
            if (!Array.isArray(data)) {
                if (data == null) {
                    return Helpers.generateJsonSchemaToData(schema, false, emptyValue);
                } else if (typeof data === 'object') {
                    return [data['*'] ? data['*'] : data];
                }
                return data;
            }
            return data.map(d => Helpers.formatDataForSchema(d, schema.items));
        } else if (schema.type === 'object' || schema.type === 'catalog') {
            if (typeof (data) !== 'object' || data === null || data === undefined) {
                return Helpers.generateJsonSchemaToData(schema, false, emptyValue);
            }
            let fixedData: any = {};
            for (const key of Object.keys(data)) {
                const trimmedKey = key.trim();
                fixedData[trimmedKey] = data[key];
            }
            let orderedData: any = {};
            for (const key of Object.keys(schema.properties)) {
                const trimmedKey = key.trim();
                if (fixedData.hasOwnProperty(trimmedKey)) {
                    orderedData[key] = Helpers.formatDataForSchema(fixedData[trimmedKey], schema.properties[key]);
                    continue;
                }
                orderedData[key] = Helpers.generateJsonSchemaToData(schema.properties[key], false, emptyValue);
            }
            let additionalData = {};
            Object.keys(fixedData).filter(k => !orderedData.hasOwnProperty(k)).forEach(k => additionalData[k] = fixedData[k]);
            return {...additionalData, ...orderedData};
        } else if (schema.type === 'file') {
            if (typeof (data) !== 'object' || data === null || data === undefined) {
                return Helpers.generateJsonSchemaToData(schema, false, emptyValue);
            } else if (!data['$documentId']) {
                return {...data, ...Helpers.generateJsonSchemaToData(schema, false, emptyValue)}
            } else {
                return data;
            }
        } else if (schema.type && typeof (data) !== schema.type) {
            if (schema.type === 'string') {
                return data === null || data === undefined ? "" : String(data);
            } else if (schema.type === 'number' || schema.type === 'integer') {
                let fixedValue = Helpers.isInt(data) ? parseInt(data) : parseFloat(data);
                if (Number.isNaN(fixedValue)) {
                    fixedValue = 0;
                }
                return fixedValue;
            } else if (schema.type === 'boolean') {
                return Helpers.castBool(data);
            }
        }
        return data;
    }

    static cleanData(data: any) {
        if (Array.isArray(data)) {
            for (const index in data) {
                data[index] = Helpers.cleanData(data[index]);
            }
            return data;
        } else if (typeof (data) === 'object' && data !== null && data !== undefined) {
            Object.keys(data).forEach(key => data[key] = Helpers.cleanData(data[key]));
            return data;
        } else if (typeof (data) === 'number') {
            return 0;
        } else if (typeof (data) === 'boolean') {
            return false;
        } else if (typeof (data) === 'string') {
            return "";
        }
        return data;
    }

    static getUserRole(user, roleName: string) {
        return user.roles.filter(userRole => userRole.name === roleName).first;
    }

    static userHasRoles(user, roles: string[]) {
        return user && user.roles.filter(role => roles.indexOf(role.name) !== -1).length > 0;
    }

    static addUserRole(user, roleName) {
        let userRole = user.roles.find(role => role.name === roleName);
        if (!userRole) {
            userRole = {name: roleName, database_catalogs: [], database_catalog_groups: []}
            user.roles.push(userRole);
        }
        return userRole;
    }

    static removeUserRole(user, roleName) {
        user.roles = user.roles.filter(role => role.name !== roleName);
    }

    static userHasDatabaseCatalogGroupPermission(user, permission, databaseCatalogGroup) {
        if (Helpers.userHasRoles(user, ['super-admin'])) {
            return true;
        }
        if (!Helpers.userHasRoles(user, [permission])) {
            return false;
        }
        const role = user.roles.filter(role => role.name === permission).first;
        if (role.database_catalogs.length === 0 && role.database_catalog_groups.length === 0) {
            return true;
        }
        return databaseCatalogGroup && role.database_catalog_groups.indexOf(databaseCatalogGroup.id) !== -1;
    }

    static userHasDatabaseCatalogPermission(user, permission, databaseCatalog) {
        if (Helpers.userHasRoles(user, ['super-admin'])) {
            return true;
        }
        if (!Helpers.userHasRoles(user, [permission])) {
            return false;
        }
        const role = user.roles.filter(role => role.name === permission).first;
        if (!databaseCatalog || role.database_catalogs.length === 0 && role.database_catalog_groups.length === 0) {
            return true;
        }
        if (databaseCatalog.group_id && role.database_catalog_groups.indexOf(databaseCatalog.group_id) !== -1) {
            return true;
        }
        return role.database_catalogs.indexOf(databaseCatalog.code) !== -1;
    }

    static userHasDataViewPermission(user, permission, dataView) {
        if (Helpers.userHasRoles(user, ['super-admin'])) {
            return true;
        }
        if (!Helpers.userHasRoles(user, [permission])) {
            return false;
        }
        const role = user.roles.find(role => role.name === permission);
        if (!dataView || role.data_views.length === 0) {
            return true;
        }
        return role.data_views.includes(dataView.catalog_id);
    }

    static isValidDataView(dataView: any, fieldIds: any[]) {
        const extraFieldIds = [];
        if (Array.isArray(dataView.aggregates)) {
            for (const aggregate of dataView.aggregates) {
                if (aggregate.func && aggregate.field_id && fieldIds.includes(aggregate.field_id)) {
                    extraFieldIds.push(`${aggregate.field_id}.${aggregate.func}`);
                }
            }
        }
        if (Array.isArray(dataView.group_by)) {
            for (const group_item of dataView.group_by) {
                if (group_item.func && group_item.field_id && fieldIds.includes(group_item.field_id)) {
                    extraFieldIds.push(`${group_item.field_id}.${group_item.func}`);
                }
            }
        }
        return Helpers.isValidDataViewFields(dataView.fields, fieldIds, extraFieldIds);
    }

    static isValidDataViewFields(fields: any[], fieldIds: any[], extraFieldIds: any[]) {
        for (const field of fields) {
            if (field.id != null && !(fieldIds.includes(field.id) || extraFieldIds.includes(field.id))) {
                return false;
            }
            const subFields = field.settings?.fields;
            if (Array.isArray(subFields)) {
                if (!Helpers.isValidDataViewFields(subFields, fieldIds, extraFieldIds)) {
                    return false;
                }
            }
        }
        return true;
    }

    static userHasAllDatabaseCatalogPermission(user, permission) {
        if (Helpers.userHasRoles(user, ['super-admin'])) {
            return true;
        }
        if (!Helpers.userHasRoles(user, [permission])) {
            return false;
        }
        const role = user.roles.filter(role => role.name === permission).first;
        return role.database_catalogs.length === 0;
    }

    static addUserDatabaseCatalogPermission(user, permission, databaseCatalogCode) {
        let role = user.roles.filter(role => role.name === permission).first;
        if (role) {
            if (role.database_catalogs.length !== 0 && role.database_catalogs.indexOf(databaseCatalogCode) === -1) {
                role.database_catalogs.push(databaseCatalogCode);
            }
        } else {
            role = {
                name: permission,
                database_catalogs: [databaseCatalogCode]
            };
            user.roles.push(role);
        }
    }

    static userHasDatabaseCatalogFieldPermission(user, permission, databaseCatalog, fieldId) {
        if (!Helpers.userHasDatabaseCatalogPermission(user, permission, databaseCatalog)) {
            return false;
        }
        const advancedDatabasePermission = user.advanced_database_permissions.filter(a => a.role_name === permission && a.database_catalog_id === databaseCatalog.id).first;
        if (advancedDatabasePermission) {
            const fieldPermission = advancedDatabasePermission.fields.filter(f => f.field_id === fieldId).first;
            if (fieldPermission) {
                return fieldPermission.enabled;
            }
        }
        return true;
    }

    static userHasDatabaseCatalogFilterPermission(user, permission, databaseCatalog, filterId) {
        if (!Helpers.userHasDatabaseCatalogPermission(user, permission, databaseCatalog)) {
            return false;
        }
        const advancedDatabasePermission = user.advanced_database_permissions.filter(a => a.role_name === permission && a.database_catalog_id === databaseCatalog.id).first;
        if (advancedDatabasePermission) {
            const filterPermission = advancedDatabasePermission.filters.filter(f => f.filter_id === filterId).first;
            if (filterPermission) {
                return filterPermission.enabled;
            }
        }
        return true;
    }

    static migrateData(originalSchema: any, newSchema: any, data: any, willMerge: boolean) {
        let newData = Helpers.deepCopy(data);
        const dataId = Helpers.convertDataToId(originalSchema, newData);
        if (willMerge) {
            return _.merge(newData, Helpers.convertIdToData(newSchema, dataId));
        } else {
            return Helpers.convertIdToData(newSchema, dataId);
        }
    }

    static setValueData(path: Array<string>, value: any, data: any) {
        for (let i = 0; i < path.length; i++) {
            const key = path[i];
            if (i === (path.length - 1)) {
                data[key] = value;
            } else {
                let currentData = data[key];
                if (currentData == null || !(typeof currentData === 'object' || Array.isArray(currentData) && typeof key === 'number')) {
                    currentData = {};
                    data[key] = currentData;
                }
                data = currentData;
            }
        }
    }

    static getValueData(path: Array<string>, data: any) {
        if (path === undefined || path === null) {
            return undefined;
        }
        if (path.length === 0) {
            return data;
        }
        let _path = [...path];
        const key = _path.shift();
        if (Array.isArray(data)) {
            if (key === '*') {
                if (_path.length === 0) {
                    return data;
                }
                let dataList = [];
                for (const item of data) {
                    const itemValue = Helpers.getValueData(_path, item);
                    if (Array.isArray(itemValue)) {
                        dataList = [...dataList, ...itemValue];
                    } else if (itemValue !== undefined && itemValue !== null) {
                        dataList.push(itemValue)
                    }
                }
                return dataList;
            }
            const arrayKey = parseInt(key);
            if (Helpers.isInt(arrayKey) && arrayKey < data.length) {
                return _path.length === 0 ? data[arrayKey] : Helpers.getValueData(_path, data[arrayKey]);
            }
        }
        if (typeof (data) !== "object" || data === null || data === undefined) {
            return null;
        }
        return _path.length === 0 ? data[key] : Helpers.getValueData(_path, data[key]);
    }

    static deleteValueData(path: Array<string>, data: any) {
        let _path = [...path];
        if (_path.length === 0) {
            return
        }
        const key = _path.shift();
        if (_path.length === 0) {
            delete data[key];
        } else {
            Helpers.deleteValueData(_path, data[key]);
        }
    }

    static getPaths(data: any, parameter: Array<string> = []) {
        let paths = [];
        if (data !== null && typeof (data) === 'object') {
            Object.keys(data).forEach(key => {
                paths = [...paths, ...Helpers.getPaths(data[key], [...parameter, key])]
            })
        } else if (!Array.isArray(data) && parameter.length > 0) {
            return [parameter];
        }
        return paths;
    }

    static convertDataToId(schema, data) {
        let dataId = {};
        if (schema.hasOwnProperty('$id')) {
            dataId[schema['$id']] = data;
        }
        if (data === undefined || data === null) {
            return dataId;
        }
        if (schema.type === 'object') {
            Object.keys(schema.properties).forEach(key => {
                dataId = {...dataId, ...Helpers.convertDataToId(schema.properties[key], data[key])}
            });
        } else if (schema.type === 'array' && (schema.items.type === 'file' || schema.items.type === 'catalog') && Array.isArray(data)) {
            dataId[schema['$id']] = data;
        } else if (schema.type === 'array' && schema.hasOwnProperty('$id') && Array.isArray(data)) {
            dataId[schema['$id']] = data.map(item => Helpers.convertDataToId(schema.items, item));
        }
        return dataId;
    }

    static getNestedFieldsFromSchema(schema, path = [], excludeTypes = [], prefixTranslationKey = null) {
        let fields = [];
        let currentField = null;
        if (schema['$id'] && path.length > 0) {
            const type = Helpers.getSchemaRealType(schema);
            if (excludeTypes.includes(type)) {
                return fields;
            }
            currentField = {
                id: schema['$id'],
                name: path[path.length - 1],
                path: path.join('.'),
                usingForeignKey: !!schema.foreignKeys,
                type
            };
            if (prefixTranslationKey) {
                currentField.translationKey = `${prefixTranslationKey}.field.${schema['$originalId'] || currentField.id}`;
            }
            fields.push(currentField);
        }
        if (schema.type === 'object' || schema.type === 'catalog' && schema.properties) {
            if (currentField) {
                currentField.children = [];
            }
            Object.keys(schema.properties).map(key => {
                const subFields = Helpers.getNestedFieldsFromSchema(schema.properties[key], [...path, key], excludeTypes, prefixTranslationKey)
                if (currentField) {
                    currentField.children.push(...subFields);
                } else {
                    fields.push(...subFields);
                }
            });
        } else if (currentField && (currentField.type === 'array' || currentField.type === 'array_of_catalog')) {
            currentField.children = Helpers.getNestedFieldsFromSchema(schema.items, [...path, '*'], excludeTypes, prefixTranslationKey)
        }
        return fields;
    }

    static getFieldsFromSchema(schema, path = [], excludeTypes = []) {
        let fields = [];
        if (schema.hasOwnProperty('$id')) {
            if (excludeTypes.indexOf(schema.type) === -1) {
                fields.push({
                    id: schema['$id'],
                    schema: schema,
                    path: path
                })
            }
        }
        if (schema.type === 'object') {
            Object.keys(schema.properties).forEach(key => {
                fields.push(...Helpers.getFieldsFromSchema(schema.properties[key], [...path, key], excludeTypes))
            });
        } else if (schema.type === 'array') {
            fields.push(...Helpers.getFieldsFromSchema(schema.items, [...path, '*'], excludeTypes))
        } else if (schema.type === 'catalog' && schema.properties) {
            Object.keys(schema.properties).forEach(key => {
                fields.push(...Helpers.getFieldsFromSchema(schema.properties[key], [...path, key], excludeTypes))
            });
        }
        return fields;
    }

    static convertSchemaToIdAsForeign(schema, catalogId, prefixTranslationKey = null) {
        catalogId = String(catalogId);
        const schemaIds = Helpers.convertSchemaToId(schema, [], prefixTranslationKey);
        const fkSchemaIds = {}
        for (const fieldId of Object.keys(schemaIds)) {
            const fkFieldId = `$foreign.${catalogId}.${fieldId}`;
            schemaIds[fieldId].schema['$id'] = fkFieldId;
            schemaIds[fieldId].schema['$originalId'] = fieldId;
            schemaIds[fieldId].path = ['$foreign', catalogId, '*', ...schemaIds[fieldId].path];
            fkSchemaIds[fkFieldId] = schemaIds[fieldId];
        }
        return fkSchemaIds;
    }

    static convertSchemaToId(schema: any, path = [], prefixTranslationKey: string = null, translationKeyPath: any = []) {
        let schemaId = {};
        if (!schema) {
            return schemaId;
        }
        if (schema['$id'] != null) {
            const field: any = {
                schema: schema,
                path: path
            };
            if (prefixTranslationKey) {
                field.translationKey = `${prefixTranslationKey}.field.${schema['$originalId'] || schema['$id']}`;
                if (!translationKeyPath) {
                    translationKeyPath = []
                }
                translationKeyPath = [...translationKeyPath, field.translationKey];
                field.translationKeyPath = translationKeyPath;
            }
            schemaId[schema['$id']] = field;
        }
        if (schema.properties && (schema.type === 'object' || schema.type === 'catalog')) {
            Object.keys(schema.properties).forEach(key => {
                schemaId = {...schemaId, ...Helpers.convertSchemaToId(schema.properties[key], [...path, key], prefixTranslationKey, translationKeyPath)}
            });
        } else if (schema.type === 'array' && schema.hasOwnProperty('$id')) {
            schemaId = {...schemaId, ...Helpers.convertSchemaToId(schema.items, [...path, '*'], prefixTranslationKey, translationKeyPath)};
        }
        return schemaId;
    }

    static convertIdToData(schema, dataId) {
        let value = undefined;
        if (schema.hasOwnProperty('$id') && dataId.hasOwnProperty(schema['$id'])) {
            value = dataId[schema['$id']];
        }
        if (schema.type === 'object') {
            value = {};
            Object.keys(schema.properties).forEach(key => {
                const childValue = Helpers.convertIdToData(schema.properties[key], dataId);
                if (childValue !== undefined) {
                    value[key] = childValue;
                }
            });
        } else if (schema.type === 'array' && (schema.items.type === 'file' || schema.items.type === 'catalog') && value != null) {
            if (Array.isArray(value)) {
                return value;
            } else if (typeof (value) === 'object') {
                return [value];
            }
        } else if (schema.type === 'array' && value !== undefined && Array.isArray(value)) {
            value = value.map(item => Helpers.convertIdToData(schema.items, item)).filter(item => item !== undefined);
        } else if (schema.type === 'file') {
            if (Array.isArray(value)) {
                return value.length > 0 ? value[0] : undefined;
            }
        } else if (schema.type === 'catalog') {
            if (Array.isArray(value)) {
                return value.length > 0 ? value[0] : undefined;
            }
        }
        return value;
    }

    static getUserDatabaseSettings(user, databaseId) {
        if (!user.settings || !user.settings.database || !user.settings.database[databaseId]) {
            return null
        }
        return user.settings.database[databaseId];
    }

    static setUserDatabaseSettings(user, databaseId, settings) {
        if (!user.settings) {
            user.settings = {};
        }
        if (!user.settings.database) {
            user.settings.database = {};
        }
        user.settings.database[databaseId] = settings;
    }

    static copyToClipboard(value) {
        const element = document.createElement('textarea');
        element.value = value;
        element.setAttribute('readonly', '');
        element.style.position = 'absolute';
        element.style.left = '-9999px';
        document.body.appendChild(element);
        const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
    }

    static castBool(value) {
        if (value === null || value === undefined) {
            return false;
        }
        if (typeof (value) === 'string') {
            return value.toLowerCase() === 'true';
        }
        return Boolean(value);
    }

    static dataHasIncludedValue(value: string, data: any) {
        const type = typeof (data);
        if (type === 'object' && (data === null || data === undefined)) {
            return false;
        } else if (type === 'object') {
            for (const key of Object.keys(data)) {
                if (Helpers.dataHasIncludedValue(value, data[key])) {
                    return true;
                }
            }
        } else if (type === 'string') {
            return data.toLowerCase().includes(value);
        } else if (type === 'number') {
            return String(data).includes(value);
        }
        return false;
    }

    static getReactInstance(element) {
        for (const key in element) {
            if (key.startsWith('__reactInternalInstance$')) {
                return element[key]['return'].stateNode
            }
        }
        return null;
    }

    static getAdvancedDatabasePermissions(user, databaseCatalog, permission_name) {
        return user.advanced_database_permissions.filter(a => a.database_catalog_id === databaseCatalog.id && a.role_name === permission_name).first;
    }

    static makeGroupDatabase(databaseList) {
        let groupDatabases = {};
        databaseList.filter(databaseCatalog => databaseCatalog.databases.length > 0).forEach(databaseCatalog => {
            const groupId = databaseCatalog.group_id ? databaseCatalog.group_id : -1;
            if (!groupDatabases.hasOwnProperty(groupId)) {
                groupDatabases[groupId] = [];
            }
            groupDatabases[groupId].push(databaseCatalog);
        });
        return groupDatabases;
    }

    static getSchemaRealType(schema) {
        if (schema.type === 'string') {
            if (schema.format === 'date-time') {
                return 'datetime';
            } else if (schema.format === 'date') {
                return 'date'
            }
        } else if (schema.type === 'array') {
            if (schema.items?.type === 'file') {
                return 'array_of_file';
            } else if (schema.items?.type === 'catalog') {
                return 'array_of_catalog';
            }
        }
        return schema.type;
    }

    static getRealType(data, schema) {
        if (schema) {
            return Helpers.getSchemaRealType(schema);
        }
        return typeof (data);
    }

    static formatTableCols(headerColumns, parent = null) {
        let cols = [];
        for (const column of headerColumns) {
            if (column.valueType && column.Header && column.accessor) {
                let col: any = {
                    accessor: column.accessor,
                    label: column.Header,
                    visible: true,
                    type: column.valueType,
                };
                if (column.disableSortBy != null) {
                    col.disableSortBy = column.disableSortBy;
                }
                if (column.schema && column.schema['$id']) {
                    col.fieldId = column.schema['$id'];
                }
                if (parent) {
                    col.parentAccessor = parent.accessor;
                }
                cols.push(col);
            }
            if (column.columns) {
                cols = [...cols, ...Helpers.formatTableCols(column.columns, column.Header ? column : parent)];
            }
        }
        return cols;
    }

    static getFilterFieldsFromTableHeader(headerColumns) {
        let fields = [];
        for (const column of headerColumns) {
            if (!column.valueType || column.valueType === 'generic' || column.valueType === 'file') {
                continue;
            }
            fields.push({
                name: column.Header,
                key: column.accessor,
                type: column.valueType
            });
            if (column.columns) {
                fields.push(...Helpers.getFilterFieldsFromTableHeader(column.columns))
            }
            if (column.valueType === 'array' && column.schema && column.path) {
                const schemaFields = Helpers.getFieldsFromSchema(column.schema);
                for (const schemaField of schemaFields) {
                    const fieldType = Helpers.getSchemaRealType(schemaField.schema);
                    if (fieldType === 'file' || schemaField.path.length === 0) {
                        continue;
                    }
                    let path = Helpers.deepCopy(schemaField.path);
                    path.splice(0, 1);
                    if (fieldType === 'catalog') {
                        if (schemaField.schema.properties?.value) {
                            fields.push({
                                name: path.join('.'),
                                key: [...column.path, ...schemaField.path, 'value'].join('.'),
                                type: schemaField.schema.properties?.value.type
                            });
                        }
                    } else {
                        fields.push({
                            name: path.join('.'),
                            key: [...column.path, ...schemaField.path].join('.'),
                            type: fieldType
                        });
                    }
                }
            }
        }
        return fields;
    }

    static setVisibleColChildren(visible, cols, tableCols) {
        for (const col of cols) {
            col.visible = visible;
            Helpers.setVisibleColChildren(visible, tableCols.filter(c => c.parentAccessor === col.accessor), tableCols);
        }
    }

    static getVisibleHeaderColumns(headerColumns, tableCols, parent = null) {
        let visibleHeaderColumns = [];
        for (const headerColumn of headerColumns) {
            if (headerColumn.accessor) {
                const col = tableCols.filter(c => !parent && c.accessor === headerColumn.accessor || parent && c.accessor === headerColumn.accessor && c.parentAccessor === parent.accessor).first;
                if (col && !col.visible) {
                    continue;
                }
            }
            if (!headerColumn.columns) {
                visibleHeaderColumns.push(headerColumn);
                continue;
            }
            const childColumns = Helpers.getVisibleHeaderColumns(headerColumn.columns, tableCols, headerColumn);
            if (childColumns.length === 0) {
                continue;
            }
            visibleHeaderColumns.push({...headerColumn, columns: childColumns});
        }
        return visibleHeaderColumns;
    }

    static getColumnById(id: string, headerColumns: Array<any>) {
        for (const headerColumn of headerColumns) {
            if (headerColumn.schema && headerColumn.schema['$id'] && headerColumn.schema['$id'] === id || headerColumn.accessor === id) {
                return headerColumn;
            }
            if (headerColumn.columns) {
                const childColumn = Helpers.getColumnById(id, headerColumn.columns);
                if (childColumn) {
                    return childColumn;
                }
            }
        }
        return null;
    }

    static generateUrlQueryFromFilters(filters) {
        let query = '';
        const logicMap = {
            '==': 'exact',
            '!=': 'exact~',
            '<': 'lt',
            '<=': 'lte',
            '>': 'gt',
            '>=': 'gte',
            'icontains': 'icontains',
            'contains': 'icontains',
            'defers': 'icontains~',
            'isnull': 'isnull'
        };
        for (const filter of filters) {
            let queryField: any = {};
            let op = logicMap[filter.logic];
            if (!op) {
                op = filter.logic;
            }
            let qValue = filter.value;
            if (qValue && typeof qValue === 'object') {
                qValue = JSON.stringify(qValue)
            }
            queryField[`${filter.field}__${op}`] = qValue;
            const queryPart = stringify(queryField);
            if (query === '') {
                query = queryPart;
            } else if (filter.gate === '||') {
                query += `&or&${queryPart}`;
            } else {
                query += `&${queryPart}`;
            }
        }
        return query;
    }

    static getFlagValues(dataContent, database) {
        let listValues = {};
        database.schema_flags.filter(flag => flag.name.trim().length > 0).forEach(flag => {
            let path = flag.path.split('/');
            if (path[0].length === 0) {
                path.shift();
            }
            const value = Helpers.getValueData(path, dataContent);
            if (value !== null && value !== undefined) {
                if (!listValues.hasOwnProperty(flag.name)) {
                    listValues[flag.name] = [];
                }
                listValues[flag.name].push({field: path.last, value, path: flag.path});
            }
        });
        return listValues;
    }

    static getPrimaryKeyField(database) {
        return Helpers.getPrimaryKeyFieldFromSchema(database.schema);
    }

    static getPrimaryKeyFieldFromSchema(schema) {
        if (!schema) {
            return null;
        }
        const schemaParts = Helpers.convertSchemaToId(schema);
        for (const fieldId of Object.keys(schemaParts)) {
            if (schemaParts[fieldId].schema.primaryKey) {
                return schemaParts[fieldId];
            }
        }
        return null;
    }

    static getPageSizeOptions(total: number, pageSizeOptions: Array<number> = [10, 15, 20, 30, 50, 100, 200, 1000]) {
        let cleanedPageSizeOptions = [];
        pageSizeOptions.some((pageSize) => {
            cleanedPageSizeOptions.push(pageSize);
            return total < pageSize;
        });
        return cleanedPageSizeOptions;
    }

    static inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    static hasSchemaSomeFieldsReadyOnly(schema) {
        if (schema.readOnly) {
            return true;
        }
        if (schema.type === 'object') {
            for (const key of Object.keys(schema.properties)) {
                if (Helpers.hasSchemaSomeFieldsReadyOnly(schema.properties[key])) {
                    return true;
                }
            }
        } else if (schema.type === 'array') {
            if (Helpers.hasSchemaSomeFieldsReadyOnly(schema.items)) {
                return true
            }
        }
        return false;
    }

    static getSortableFieldsFromSchemaIds(schemaIds: any) {
        return _.map(schemaIds, (field) => field.schema.sortable ? field : null).filter(f => f !== null);
    }

    static getPreviousDatabase(currentDatabase, databases) {
        databases = databases.sort(Helpers.arrayCompareValues('version', 'ASC')).map(item => item);
        let beforeDatabase = null;
        for (const database of databases) {
            if (database.id === currentDatabase.id) {
                break;
            }
            beforeDatabase = database;
        }
        return beforeDatabase;
    }

    static checkUploadFileSizeLimit(file: File): boolean {
        // @ts-ignore
        const fileSizeLimit = UPLOAD_FILE_SIZE_LIMIT;
        if (!fileSizeLimit) {
            return true;
        }
        return file.size <= fileSizeLimit;
    }

    static convertSize(sizeBytes: number): string {
        if (sizeBytes === 0) {
            return '0B';
        }
        const sizeName = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(sizeBytes) / Math.log(1024));
        const p = Math.pow(1024, i);
        const s = (sizeBytes / p).toFixed(2);
        return `${s} ${sizeName[i]}`;
    }

    static assignDatabaseCatalogPermission(user, databaseCatalog) {
        for (const permission of ['database-read', 'database-update', 'database-delete', 'database-publish', 'data-read', 'data-create', 'data-update', 'data-delete']) {
            Helpers.addUserDatabaseCatalogPermission(user, 'database-read', databaseCatalog.code);
        }
    }

    static convertFieldsToSchema(fields, schemaIds) {
        let schema = {
            type: 'object',
            properties: {}
        }
        fields.forEach(field => {
            let fieldSchema: any = {
                '$id': field.id,
                type: field.type,
                '$path': field.path
            }
            if (field.settings) {
                if (field.settings.listingValue) {
                    fieldSchema['x-listing-value'] = field.settings.listingValue;
                }
            }
            if (!fieldSchema.type && schemaIds && schemaIds[field.id]) {
                fieldSchema.type = Helpers.getSchemaRealType(schemaIds[field.id].schema);
            }
            schema.properties[field.display] = fieldSchema;
            if (fieldSchema.type === 'array_of_catalog') {
                fieldSchema.items = {
                    type: 'catalog'
                }
                fieldSchema.type = 'array';
            }
            if (field.settings && field.settings.fields && field.settings.fields.length > 0) {
                if (fieldSchema.type === 'array') {
                    schema['properties'][field.display].items = Helpers.convertFieldsToSchema(field.settings.fields, schemaIds);
                } else if (fieldSchema.type === 'object') {
                    schema['properties'][field.display] = {...schema['properties'][field.display], ...Helpers.convertFieldsToSchema(field.settings.fields, schemaIds)};
                }
            }
        });
        return schema;
    }

    static validateSchema(schema) {
        if (typeof schema !== 'object' || schema == null || typeof schema.type !== 'string') {
            return false;
        }
        if (schema.type === 'object') {
            if (typeof schema.properties !== 'object' || schema.properties == null) {
                return false;
            }
            for (const key of Object.keys(schema.properties)) {
                if (!Helpers.validateSchema(schema.properties[key])) {
                    return false;
                }
            }
        } else if (schema.type === 'array') {
            if (!Helpers.validateSchema(schema.items)) {
                return false;
            }
        }
        return true;
    }


    static validateImportDatabase(data) {
        if (data == null || typeof (data) !== 'object' || data.groups && !Array.isArray(data.groups) || data.databases && !Array.isArray(data.databases)) {
            return false
        }
        if (data.groups) {
            for (const group of data.groups) {
                if (typeof (group.name) !== 'string' || !Array.isArray(group.databases)) {
                    return false;
                }
                for (const eachDatabase of group.databases) {
                    if (typeof (eachDatabase.catalog_name) !== 'string' || typeof (eachDatabase.code) !== 'string' || !Helpers.validateSchema(eachDatabase.schema)) {
                        return false;
                    }
                }
            }
        }
        if (data.databases) {
            for (const eachDatabase of data.databases) {
                if (typeof (eachDatabase.catalog_name) !== 'string' || typeof (eachDatabase.code) !== 'string' || !Helpers.validateSchema(eachDatabase.schema)) {
                    return false;
                }
            }
        }
        return true
    }

    static generateSchemaToFields(schema, includedPrimaryKey = false, parameter = []) {
        let fields = [];
        if (schema.type === 'object' || schema.type === 'catalog') {
            for (const key of Object.keys(schema.properties)) {
                const _parameter = [...parameter, key];
                if (schema.properties[key].type === 'object' || schema.properties[key].type === 'catalog') {
                    const subFields = Helpers.generateSchemaToFields(schema.properties[key], includedPrimaryKey, _parameter);
                    if (schema.properties[key].type === 'catalog') {
                        const isRequired = schema.required && schema.required.indexOf(key) !== -1;
                        for (const subField of subFields) {
                            subField.required = isRequired;
                        }
                    }
                    fields.push(...subFields)
                } else if (schema.properties[key].type === 'array') {
                    const subType = Helpers.getSchemaRealType(schema.properties[key]);
                    if (subType === 'array_of_file' || subType === 'array_of_catalog') {
                        let field: any = {
                            name: _parameter.join('.'),
                            required: schema.required && schema.required.indexOf(key) !== -1,
                            path: _parameter,
                            schema: schema.properties[key],
                            type: Helpers.getSchemaRealType(schema.properties[key])
                        };
                        if (schema.properties[key]['$id']) {
                            field.id = schema.properties[key]['$id'];
                        }
                        if (schema.properties[key].primaryKey) {
                            field.usingPrimaryKey = true;
                        }
                        fields.push(field);
                    }
                    fields.push(...Helpers.generateSchemaToFields(schema.properties[key].items, includedPrimaryKey, [..._parameter, '*']))
                } else {
                    if (!schema.properties[key].primaryKey || includedPrimaryKey) {
                        let field: any = {
                            name: _parameter.join('.'),
                            required: schema.required && schema.required.indexOf(key) !== -1,
                            path: _parameter,
                            schema: schema.properties[key],
                            type: Helpers.getSchemaRealType(schema.properties[key])
                        };
                        if (schema.properties[key]['$id']) {
                            field.id = schema.properties[key]['$id'];
                        }
                        if (schema.properties[key].primaryKey) {
                            field.usingPrimaryKey = true;
                        }
                        fields.push(field);
                    }
                }
            }
        }
        return fields;
    }

    static decodeDataURI(uri: string): IDecodedDataURI {
        if (!uri || !uri.startsWith('data:')) {
            return null;
        }
        const parts = uri.substring(5).split(';');
        if (parts.length < 2) {
            return null;
        }
        const data: IDecodedDataURI = {
            mimeType: parts.shift()
        }
        while (parts.length > 0) {
            let p = parts.shift();
            if (parts.length === 0) {
                const p1 = p.split(',', 2);
                if (p1.length === 2) {
                    data.content = p1[1]
                }
                p = p1[0].trim();
            }
            const a = p.split('=', 2);
            if (a.length === 2) {
                if (!data.attributes) {
                    data.attributes = {};
                }
                data.attributes[a[0]] = a[1];
            } else if (parts.length === 0 && p !== '') {
                data.extension = p;
            }
        }
        return data;
    }

    static initBlob(data: IDecodedDataURI) {
        const charset = data.attributes && data.attributes.charset || 'utf-8';
        const byteArray = data.extension === 'base64' ? Base64.toByteArray(data.content) : (new TextEncoder()).encode(decodeURIComponent(data.content));
        return new Blob([byteArray], {type: `${data.mimeType};charset=${charset}`})
    }

    static resolveChangesFromSchema(schema: any, changesData: any) {
        const schemaId = schema['x-id'] || schema['$id'];
        if (schemaId && changesData[schemaId] != null) {
            return changesData[schemaId];
        }
        let max = 0;
        if ((schema.type === 'object' || schema.type === 'catalog') && schema.properties) {
            for (const subSchema of Object.values(schema.properties)) {
                const r = Helpers.resolveChangesFromSchema(subSchema, changesData);
                max = Math.max(max, r);
                if (schemaId && r > 0) {
                    Helpers.resolveChangesFromSubSchema(changesData, schemaId, subSchema);
                }
            }
        } else if (schema.type === 'array') {
            max = Helpers.resolveChangesFromSchema(schema.items, changesData);
            if (schemaId && max > 0) {
                let subSchemaId = schema.items['x-id'] || schema.items['$id'];
                if (subSchemaId) {
                    Helpers.resolveChangesFromSubSchema(changesData, schemaId, schema.items);
                } else if (schema.items.type === 'object' || schema.type === 'catalog') {
                    for (const subSchema of Object.values(schema.items.properties)) {
                        Helpers.resolveChangesFromSubSchema(changesData, schemaId, subSchema);
                    }
                }
            }
        }
        if (schemaId) {
            changesData[schemaId] = max;
        }
        return max;
    }

    static resolveChangesFromSubSchema(changesData: any, schemaId: number, subSchema: any) {
        const subSchemaId = subSchema['x-id'] || subSchema['$id'];
        if (subSchemaId) {
            const prefixKey = `${subSchemaId}.`;
            const fKeys = Object.keys(changesData).filter(k => k.startsWith(prefixKey));
            for (const key of fKeys) {
                const indexPath = key.substring(prefixKey.length);
                const parts = indexPath.split('.');
                let objKey = `${schemaId}`;
                for (const part of parts) {
                    objKey += `.${part}`;
                    changesData[objKey] = Math.max(changesData[objKey] || 0, changesData[key]);
                }
            }
        }
    }

    static getFieldChangeKey(schema: any, path?: any[]) {
        const schemaId = schema ? (schema['$id'] || schema['x-id']) : null
        let fieldChangeKey = schemaId;
        if (schemaId && path) {
            const indexPath = path.filter(k => typeof k === 'number');
            if (indexPath.length > 0) {
                fieldChangeKey = `${schemaId}.${indexPath.join('.')}`
            }
        }
        return fieldChangeKey;
    }

}
